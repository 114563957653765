// const url = "http://192.168.43.155:8001/api/"
// const url = "http://127.0.0.1:8001/api/"
const url = "https://katalog.zuresoft.com/api/"

export const URL = {
    // Auth
    login: url + 'login',
    user: url + 'user',
    userUpdate: url + 'user-update',
    paswordUpdate: url + 'password-update',

    //Product
    product: url + 'product'
}